import React, { useRef, useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import Button from "../common/Button";
import ToggleIcon from "./ToggleIcon";
import UseWindowDims from "../common/UseWindowDims";

const renderNarrative = (narrative) => {
    return narrative.split("<br />").map((section, index) => {
        // If the section contains list items, split them into an unordered list
        if (section.includes("- ")) {
            const listItems = section
                .split("- ") // Split by list marker
                .filter((item) => item.trim() !== "") // Remove empty items
                .map((item, idx) => <li key={idx}>{item.trim()}</li>);

            return <ul key={index}>{listItems}</ul>;
        }

        return <p key={index}>{section.trim()}</p>; // Render as paragraph
    });
};

const HighlightedServiceCard = ({ serviceName, serviceNarrative, imgSrc, imgAlt, isOpen, onToggle, disableImgBorder = false }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState("0px");
    const windowDims = UseWindowDims();

    useEffect(() => {
        if (isOpen && contentRef.current) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setHeight("0px");
        }
    }, [isOpen, windowDims]); // Adjust height when the window resizes

    return (
        <HighlightedServiceCardWrapper>
            <ServiceHeader onClick={onToggle}>
                <ServiceName className="secondary-title">{serviceName}</ServiceName>
                <ToggleIcon expanded={isOpen} />
            </ServiceHeader>
            <CollapsibleSection ref={contentRef} $expanded={isOpen} $height={height}>
                <ServiceContent>
                    <ServiceNarrative className="left-margin text">
                        {renderNarrative(serviceNarrative)}
                    </ServiceNarrative>
                    <ServiceImg className="right-margin" src={imgSrc} alt={imgAlt} $disableImgBorder={disableImgBorder} />
                </ServiceContent>
                <StyledHashLink to="#contact" smooth>
                    <StyledButton label="Request a free quote" buttonWidth="300px" />
                </StyledHashLink>
            </CollapsibleSection>
        </HighlightedServiceCardWrapper>
    );
};

export default HighlightedServiceCard;


const HighlightedServiceCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(to left, var(--secondary-color), var(--primary-color));
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  margin: 10px 140px 10px 140px;
  width: 100%;
`;

const ServiceHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    align-items: center;

    width: 100%;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 740px) {
        padding: 5px 0px 5px 0px;
    }
`;

const ServiceName = styled.h2`
    color: var(--accent-color);
    font-size: 40px;
    padding-left: 50px;

    @media (max-width: 740px) {
        font-size: 25px;
    }

    @media (max-width: 561px) {
        padding-left: 25px;
    }
`;

const CollapsibleSection = styled.div`
    max-height: ${(props) => (props.$expanded ? props.$height : "0px")};
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ServiceContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 740px) {
        flex-direction: column-reverse;
        width: 80%;
    }
`;

const ServiceNarrative = styled.p`
    display: flex;
    flex-direction: column;
    color: black;
    flex: 2;
    max-width: 60%;

    @media (max-width: 861px) {
        font-size: 15px;
    }
`;

const ServiceImg = styled.img`
    border: ${(props) => (props.$disableImgBorder ? "none" : "5px solid var(--accent-color)")};
    min-width: 150px;
    max-width: 400px;
    height: auto;
    object-fit: contain;
    flex: 1; /* Allows image to grow and shrink */

    @media (max-width: 740px) {
        width: 50%;
    }
`;

const StyledHashLink = styled(HashLink)`
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px 10px 0px;
`;

const StyledButton = styled(Button)`
    margin-bottom: 20px;

    @media (max-width: 740px) {
        margin-bottom: auto;
        font-size: 15px;
    }
`;