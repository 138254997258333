import React from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import LinksData from "./LinksData";

const DesktopLinks = () => {
  return (
    <MenuButtons>
      {LinksData.map((link, index) => (
        <Item data-testid={"menu-item-" + link.label} key={index}>
          <HashLink
            to={link.hashLink}
            smooth
            style={{ textDecoration: "none" }}
            className="nav-link"
          >
            <Label>{link.label}</Label>
          </HashLink>
        </Item>
      ))}
    </MenuButtons>
  );
};

export default DesktopLinks;

const MenuButtons = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  text-align: center;
  margin: 12px 12px;
  padding: 3px;
  :hover {
    opacity: 70%;
  }
`;

const Label = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin: 0 10px;
  text-decoration: none;

  @media (min-width: 741px) and (max-width: 1800px) {
    font-size: 25px;
  }
`;
