import styled from "styled-components";

const ContentWrapper = styled.div`
    // Center all content
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 1500px; /* Adjust based on your preference */
    margin: 0 auto; /* Centers the content */
    padding-left: min(5vw, 70px); /* Ensures at least 24px padding */
    padding-right: min(5vw, 70px); /* Ensures at least 24px padding */
    box-sizing: border-box;

    @media (max-width: 740px) {
        padding: 0 24px; /* Smaller padding for mobile */
    }
`;

export default ContentWrapper;