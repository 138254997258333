import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import LRBLogoLarge from "../imgs/lrb-logo-large.svg";
import LandingPageVideo from "../videos/landing-page-video-compressed.mp4";

const Home = () => {
  return (
    <>
      <HomeSection className="section" id="home">
        <BackgroundVideo autoPlay loop muted>
          <source src={LandingPageVideo} type="video/mp4" />
        </BackgroundVideo>
        <ContentWrapper>
          <Logo src={LRBLogoLarge}></Logo>
          <LinkWrapper>
            <StyledHashLink to="#contact" smooth className="home-link">
              <LinkText>Request service </LinkText>
              <Arrow>↓</Arrow>
            </StyledHashLink>
          </LinkWrapper>
        </ContentWrapper>
      </HomeSection>
    </>
  );
};

export default Home;

const HomeSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0px;

  @media (max-width: 740px) {
    flex-direction: column-reverse;
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-width: 100vw;
  object-fit: cover; /* Ensures the video covers the entire section */
  object-position: center;
  z-index: -1; /* Keeps the video behind other content */
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  height: auto;
  width: 420px;
  margin-bottom: 20px;

  @media (min-width: 741px) and (max-width: 1800px) {
    width: 300px;
  }

  @media (max-width: 740px) {
    width: 250px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;

  @media (min-width: 741px) and (max-width: 1800px) {
    font-size: 20px;
  }

  @media (max-width: 740px) {
    font-size: 15px;
    margin: auto;
  }
`;

const Arrow = styled.p`
  margin: 0 0 0 5px;
  transition: transform 0.2s ease;
`;

const StyledHashLink = styled(HashLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;

  &:hover ${Arrow} {
    transform: translateY(5px);
    opacity: 70%;
  }
`;

const LinkText = styled.p`
  margin: 0;

  text-shadow: 2px 2px 0px black, -2px -2px 0px black, -2px 2px 0px black, 2px -2px 0px black;
`;
