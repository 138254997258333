import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Copyright = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Variables to see where scroll is at
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop;
            const clientHeight = document.documentElement.clientHeight;

            // Variables to track what needs changed
            const displayFooter = scrollTop + clientHeight >= scrollHeight - 20;
            
            // Transition in the footer
            if (displayFooter) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <FooterWrapper $isVisible={isVisible}>
            <CopyrightText>
                Copyright © 2025 LR Brabham, Inc. - All Rights Reserved.
            </CopyrightText>
        </FooterWrapper>
    );
};

export default Copyright;


const FooterWrapper = styled.footer`
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: opacity 0.6s ease-in-out;
    height: 2.5rem; 
`;

const CopyrightText = styled.p`
    color: white;
    font-size: 14px;
    text-align: center;
`;
