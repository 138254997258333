import React from "react";
import styled from "styled-components";
import Residential from "../../imgs/residential.svg";
import Commercial from "../../imgs/commercial.svg";
import Industrial from "../../imgs/industrial.svg";
import GeneralServiceCard from "./GeneralServiceCard";
import Button from "../common/Button";
import { HashLink } from "react-router-hash-link";
import ContentWrapper from "../common/ContentWrapper";

const GeneralServices = () => {
  const GeneralServiceContent = [
      {
          header: "Residential Services",
          description: `
            L.R. Brabham Inc. provides a variety of residential electrical services in the Eugene, Springfield, Corvallis, Cottage
            Grove, and surrounding areas. These services include troubleshooting and repairing faulty circuits or devices, electric service
            upgrades, lighting installations or replacements, wiring for devices, and home office networking to name a few. We offer
            prompt response to urgent electrical issues to ensure your homes safety and reliability.
          `,
          image: Residential,
          imageDescription: "A picture of a residential building",
          imageSize: "small"
      },
      {
          header: "Commercial Services",
          description: `
            L.R. Brabham Inc., offers a comprehensive range of commercial electrical services tailored to meet the needs of businesses
            in the Eugene, Springfield, Corvallis, Cottage Grove, and surrounding areas. As a premier union electrical contractor with
            over 40 years of experience, our company specializes in electrical design, installation, and repair for commercial properties.
            Our services include complete wiring and cabling for commercial buildings, ensuring reliable and code-compliant electrical
            systems. We also provide the installation of light fixtures to enhance functionality or decor, and service upgrades to improve
            safety and efficiency. Additionally, L.R. Brabham Inc. offers emergency electrical services with a commitment to quick and
            efficient solutions. Our team of skilled, licensed electricians is equipped to handle projects of any scale, from small fixes
            to full electrical system design and implementation, all while maintaining competitive pricing and a focus on customer satisfaction.
          `,
          image: Commercial,
          imageDescription: "A picture of a commercial building",
          imageSize: "large",
          rowReverse: true
      },
      {
          header: "Industrial Services",
          description: `
            L.R. Brabham Inc. provides a variety of industrial services as part of its comprehensive electrical solutions. For industrial
            applications, L.R. Brabham Inc. provides specialized services tailored to meet the complex electrical needs of manufacturing
            facilities, warehouses, and other large-scale operations.
            <br />
            Our industrial services include: Electrical design and installation services for industrial projects, wiring and cabling
            solutions for industrial equipment and systems, such as machinery, security cameras, and networking infrastructure, emergency
            electrical repairs are available 24/7 in order to address urgent electrical issues in industrial settings, such as power outages
            or equipment failures, perform electric service upgrades to enhance capacity and safety, as well as routine maintenance to ensure
            industrial systems operate efficiently and reliably, and specialized installations such as the installation of heavy-duty circuit
            breakers, grounding systems, and other industrial-grade components designed to handle high-voltage and high-demand environments.
          `,
          image: Industrial,
          imageDescription: "A picture of an industrial building",
          imageSize: "medium"
      },
  ];
  return (
    <ServicesSection className="grey-section section" id="services">
        <ContentWrapper style={{ flexDirection: "column" }}>
          <h1 className="title" >Services</h1>
          {GeneralServiceContent.map((generalServiceContent, index) => (
              <GeneralServiceCard
                  key={index}
                  header={generalServiceContent.header}
                  description={generalServiceContent.description}
                  image={generalServiceContent.image}
                  imageDescription={generalServiceContent.imageDescription}
                  imageSize={generalServiceContent.imageSize}
                  rowReverse={generalServiceContent.rowReverse ? generalServiceContent.rowReverse : false}
              />
          ))}
          <HashLink to="#highlighted-services" smooth style={{ textDecoration: "none", alignSelf: "center" }} >
              <StyledButton label="See more about our services" variant="grey-section" buttonWidth="auto" />
          </HashLink>
        </ContentWrapper>
    </ServicesSection>
  );
};

export default GeneralServices;

const ServicesSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: black;
`;

const StyledButton = styled(Button)`
    text-decoration: none;
    margin-top: 20px;
    max-width: 500px;
    padding: 5px 20px;

    @media (max-width: 740px) {
        width: 300px;
        height: auto;
        font-size: 15px;
    }
`;