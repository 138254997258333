import styled from "styled-components";
import DesktopLinks from "./DesktopLinks";

const DesktopNav = () => {
    return (
      <Nav>
        <Wrapper>
          <DesktopLinks />
        </Wrapper>
      </Nav>
    );
};

export default DesktopNav;

const Nav = styled.nav`
  @media (max-width: 740px) {
    display: none;
  }
`;

const Wrapper = styled.ul`
  display: flex;
  margin: auto;
  @media (max-width: 740px) {
    display: none;
  }
`;
