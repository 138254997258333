import React from "react";
import styled from "styled-components";

const HamburgerMenu = ({display, toggleDisplay}) => {
  return (
    <MenuButton onClick={() => toggleDisplay(!display)}>
      <MenuIcon className={display ? "open" : ""}>
        <span></span>
        <span></span>
        <span></span>
      </MenuIcon>
    </MenuButton>
  );
};

export default HamburgerMenu;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
`;

const MenuIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  cursor: pointer;
  position: relative;

  span {
    background-color: white;
    height: 3px;
    width: 100%;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    position: absolute;
  }

  span:nth-child(1) {
    top: 0;
  }
  span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  span:nth-child(3) {
    bottom: 0;
  }

  &.open span:nth-child(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg);
  }
`;
