import React from "react";
import styled from "styled-components";
// import LinkedIn from "../../imgs/LinkedIn.svg";
import Facebook from "../../imgs/Facebook.svg";
import NECA from "../../imgs/neca.svg";
import IBEW from "../../imgs/ibew-neca.svg";
import Qmerit from "../../imgs/qmerit-badge.svg";
import UseWindowDims from "../common/UseWindowDims";

const renderGeneralCompanyInfo = () => {
    const socialMediaLinks = [
        { href: "https://www.facebook.com/LRBrabhamInc/", src: Facebook, alt: "Facebook logo" },
        // { href: "https://www.linkedin.com/company/lrbrabhaminc/", src: LinkedIn, alt: "LinkedIn logo" },
    ];

    return (
        <GeneralCompanyInfo>
            <CompanyName>L.R. Brabham Inc.</CompanyName>
            <CompanyInformation className="text">68 W Q St, Springfield, OR 97477</CompanyInformation>
            <CompanyInformation className="text">541-747-6638</CompanyInformation>
            <CompanyInformation className="text" style={{ fontWeight: "bold" }}>CCB #8699</CompanyInformation>
            <CompanyInformation className="text" style={{ fontWeight: "bold" }}>ESB Certification #10715</CompanyInformation>
            <SocialMediaLinkWrapper>
                {socialMediaLinks.map((link, index) => (
                    <SocialMediaLink key={index} href={link.href} target="_blank" rel="noreferrer">
                        <SocialMediaLogo src={link.src} alt={link.alt} />
                    </SocialMediaLink>
                ))}
            </SocialMediaLinkWrapper>
        </GeneralCompanyInfo>
    );
};

const renderTopImages = () => {
    return (
        <ImageContainer>
            <ImageLink href="https://www.orpacneca.org/" target="_blank" rel="noreferrer">
                <Image src={NECA} alt="NECA logo" />
            </ImageLink>
            <ImageLink href="https://www.ibew280.org/" target="_blank" rel="noreferrer">
                <Image src={IBEW} alt="IBEW NECA logo" />
            </ImageLink>
        </ImageContainer>
    );
};

const renderBottomImages = () => {
    return (
        <ImageContainer>
            <ImageLink
                href="https://www.bbb.org/us/or/springfield/profile/electrician/l-r-brabham-inc-1296-55002279/#sealclick"
                target="_blank"
                rel="noreferrer"
            >
                <Image
                    src="https://seal-alaskaoregonwesternwashington.bbb.org/seals/blue-seal-150-110-bbb-55002279.png"
                    style={{ "border": 0 }}
                    alt="L R Brabham Inc BBB Business Review"
                />
            </ImageLink>
            <ImageLink href="https://qmerit.com/" target="_blank" rel="noreferrer">
                <Image src={Qmerit} alt="Qmerit logo" />
            </ImageLink>
        </ImageContainer>
    );
};

const CompanyInfo = () => {
    const {width} = UseWindowDims();

    const topImages = renderTopImages();
    const bottomImages = renderBottomImages();
    const generalInfo = renderGeneralCompanyInfo();

    return (
        <CompanyInfoWrapper>
            {width > 1000 ?
                <>
                {topImages}
                {generalInfo}
                {bottomImages}
                </> :
                <>
                    {generalInfo}
                    <SmallScreenImageWrapper>
                        {topImages}
                        {bottomImages}
                    </SmallScreenImageWrapper>
                </>
        }
        </CompanyInfoWrapper>
    )
};

export default CompanyInfo;

const CompanyInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
`;

const GeneralCompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex: 1;

    @media (max-width: 740px) {
        margin: 10px;
    }
`;

const SmallScreenImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    @media (max-width: 740px) {
        flex-direction: column;
        justify-content: center;
    }
`;

const ImageLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    min-width: 50px;
    max-width: 150px;
    height: auto;
    object-fit: contain;

    @media (max-width: 740px) {
        width: 75%;
    }
`;

const CompanyName = styled.h2`
    margin: 10px 0px;
    @media (max-width: 740px) {
        font-size: 20px;
    }
`;

const CompanyInformation = styled.p`
    margin: 3px 0px;
`;

const SocialMediaLinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
`;

const SocialMediaLink = styled.a`
    padding: 0px 5px;
`;

const SocialMediaLogo = styled.img`
    width: 50px;
    height: auto;

    @media (max-width: 740px) {
        width: 40px;
    }
`;
