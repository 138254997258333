import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Button from "../common/Button";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import AddressForm from "./AddressForm";

const OREGON_ZIP_CODES = /^97[0-9]{3}$/; // Oregon zip codes start with 97

const RECAPTCHA_SITE_KEY = "6LdhNgMrAAAAALCd4XnqDW6xwp4Hu9nAL3dQ_qfl";

const ContactForm = ({ setModalIsShown, setModalMessage, setSuccessfulSubmission }) => {
    const [formData, setFormData] = useState({
        firstName: "", lastName: "", email: "", phone: "",
        serviceStreet: "", serviceAddressLine2: "", serviceCity: "", serviceState: "OR", serviceCountry: "United States", serviceZip: "",
        billingStreet: "", billingAddressLine2: "", billingCity: "", billingState: "", billingCountry: "", billingZip: "",
        message: "",
    });
    const [sameAsService, setSameAsService] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null); // State to store the reCAPTCHA token
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [billingStateList, setBillingStateList] = useState([]);
    const [billingCitiesList, setBillingCitiesList] = useState([]);
    const [countryID, setCountryID] = useState(233);
    const [stateID, setStateID] = useState(1415);
    const [cityID, setCityID] = useState(null);
    const [billingCountryID, setBillingCountryID] = useState(null);
    const [billingStateID, setBillingStateID] = useState(null);
    const [billingCityID, setBillingCityID] = useState(null);

    const loadOrClear = async ({ 
        id, 
        fetchFn, 
        setFn, 
        logMessage 
    }) => {
        if (!id) {
            setFn([]);
            if (logMessage) console.error(logMessage);
            return;
        }
    
        const result = await fetchFn(id);
        setFn(result);
    };

    // Load all countries once
    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
    }, []);

    // Load service states when country changes
    useEffect(() => {
        loadOrClear({
            id: countryID,
            fetchFn: (id) => GetState(parseInt(id)),
            setFn: setStateList,
        });
    }, [countryID]);

    // Load service cities when state changes
    useEffect(() => {
        if (!stateID) {
            setCitiesList([]);
            return;
        }
    
        if (!countryID) {
            loadOrClear({
                id: null,
                setFn: setCitiesList,
                logMessage: "The stateID was changed but countryID is not set.",
            });
            return;
        }
    
        loadOrClear({
            id: `${countryID},${stateID}`, // key
            fetchFn: () => GetCity(parseInt(countryID), parseInt(stateID)),
            setFn: setCitiesList,
        });
    }, [countryID, stateID]);

    // Load billing states when billing country changes
    useEffect(() => {
        loadOrClear({
            id: billingCountryID,
            fetchFn: (id) => GetState(parseInt(id)),
            setFn: setBillingStateList,
        });
    }, [billingCountryID]);    
    
    // Load billing cities when billing state changes
    useEffect(() => {
        if (!billingStateID) {
            setBillingCitiesList([]);
            return;
        }
    
        if (!billingCountryID) {
            loadOrClear({
                id: null,
                setFn: setBillingCitiesList,
                logMessage: "The billingStateID was changed but billingCountryID is not set.",
            });
            return;
        }
    
        loadOrClear({
            id: `${billingCountryID},${billingStateID}`,
            fetchFn: () => GetCity(parseInt(billingCountryID), parseInt(billingStateID)),
            setFn: setBillingCitiesList,
        });
    }, [billingCountryID, billingStateID]);

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaToken(value); // Set the reCAPTCHA response token
    };

    const validateForm = () => {
        if (!recaptchaToken) {
            return {
                valid: false,
                message: "Please complete the reCAPTCHA verification."
            };
        }
    
        if (!OREGON_ZIP_CODES.test(formData.serviceZip)) {
            return {
                valid: false,
                message: "We currently only accept inquiries for locations in the state of Oregon. Make sure the zip code you entered is correct."
            };
        }
    
        return { valid: true };
    };
    
    const lookupLocationNames = () => {
        const serviceCountry = countriesList.find(c => parseInt(c.id) === parseInt(countryID))?.name || "";
        const serviceState = stateList.find(c => parseInt(c.id) === parseInt(stateID))?.name || "";
        const serviceCity = citiesList.find(c => parseInt(c.id) === parseInt(cityID))?.name || "";
    
        let billingCountry = "";
        let billingState = "";
        let billingCity = "";
    
        if (!sameAsService) {
            billingCountry = countriesList.find(c => parseInt(c.id) === parseInt(billingCountryID))?.name || "";
            billingState = billingStateList.find(c => parseInt(c.id) === parseInt(billingStateID))?.name || "";
            billingCity = billingCitiesList.find(c => parseInt(c.id) === parseInt(billingCityID))?.name || "";
        }
    
        return { serviceCountry, serviceState, serviceCity, billingCountry, billingState, billingCity };
    };
    
    const buildSubmissionData = (locationData) => {
        const {
            serviceCountry, serviceState, serviceCity,
            billingCountry, billingState, billingCity
        } = locationData;
    
        return sameAsService
            ? {
                ...formData,
                serviceCountry,
                serviceState,
                serviceCity,
                billingStreet: formData.serviceStreet,
                billingAddressLine2: formData.serviceAddressLine2,
                billingCountry: serviceCountry,
                billingState: serviceState,
                billingCity: serviceCity,
                billingZip: formData.serviceZip
            }
            : {
                ...formData,
                serviceCountry,
                serviceState,
                serviceCity,
                billingCountry,
                billingState,
                billingCity,
            };
    };
    
    const resetForm = () => {
        // Need to reset these in reverse order
        setCityID(null);
        setStateID(1415);
        setCountryID(233);
        setBillingCityID(null);
        setBillingStateID(null);
        setBillingCountryID(null);

        setFormData({
            firstName: "", lastName: "", email: "", phone: "",
            serviceStreet: "", serviceAddressLine2: "", serviceCity: "", serviceState: "OR", serviceCountry: "United States", serviceZip: "",
            billingStreet: "", billingAddressLine2: "", billingCity: "", billingState: "", billingCountry: "", billingZip: "",
            message: "",
        });

        // Reset "same as service" checkbox
        setSameAsService(false);
    };

    const sendSubmissionEmail = async (submissionData) => {
        return emailjs.send(
            "default_service",
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            { ...submissionData, "g-recaptcha-response": recaptchaToken },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validation = validateForm();
        if (!validation.valid) {
            setModalMessage(validation.message);
            setModalIsShown(true);
            setSuccessfulSubmission(false);
            return;
        }

        const locationData = lookupLocationNames();
        const submissionData = buildSubmissionData(locationData);

        try {
            await sendSubmissionEmail(submissionData);

            setModalMessage("Your message has been sent! You'll hear back from us shortly.");
            setModalIsShown(true);
            setSuccessfulSubmission(true);
            resetForm();
        } catch (error) {
            console.error("Error sending email:", error);
            setModalMessage("Failed to send message. Please try again later.");
            setModalIsShown(true);
            setSuccessfulSubmission(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className="form-row">
                <input className="form-input" type="text" name="firstName" value={formData.firstName} placeholder="First Name*" onChange={handleFormChange} required />
                <input className="form-input" type="text" name="lastName" value={formData.lastName} placeholder="Last Name*" onChange={handleFormChange} required />
            </div>
            <div className="form-row">
                <input className="form-input" type="email" name="email" value={formData.email} placeholder="Email*" onChange={handleFormChange} required />
                <input className="form-input" type="text" name="phone" value={formData.phone} placeholder="Phone*" onChange={handleFormChange} required />
            </div>

            <AddressForm
                type="service"
                formData={formData}
                handleFormChange={handleFormChange}
                countriesList={countriesList}
                stateList={stateList}
                citiesList={citiesList}
                setCountryID={setCountryID}
                setStateID={setStateID}
                setCityID={setCityID}
                countryID={countryID}
                stateID={stateID}
                cityID={cityID}
                disabledCountry={true} // Only allow service in the U.S.
                disabledState={true} // Only allow service in Oregon
                disabledCity={false}
            />

            <CheckboxContainer>
                <StyledCheckbox type="checkbox" checked={sameAsService} onChange={() => {setSameAsService(!sameAsService)}} />
                <label>Billing address is the same as service address</label>
            </CheckboxContainer>

            {!sameAsService && (
                <>
                    <AddressForm
                        type="billing"
                        formData={formData}
                        handleFormChange={handleFormChange}
                        countriesList={countriesList}
                        stateList={billingStateList}
                        citiesList={billingCitiesList}
                        setCountryID={setBillingCountryID}
                        setStateID={setBillingStateID}
                        setCityID={setBillingCityID}
                        countryID={billingCountryID}
                        stateID={billingStateID}
                        cityID={billingCityID}
                        disabledCountry={false}
                        disabledState={!billingCountryID}
                        disabledCity={!billingStateID}
                    />
                </>
            )}

            <div className="form-row">
                <Textarea
                    name="message"
                    value={formData.message}
                    placeholder="Message*"
                    onChange={handleFormChange}
                    required
                />
            </div>

            <StyledReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
            />

            <Button label="Send" type="submit" style={{alignSelf: "center", marginTop: "15px"}} />
        </Form>
    )
};

export default ContactForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  input {
    margin-right: 10px;
  }
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 4px solid white;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:checked {
    appearance: auto;
  }
`;

const Textarea = styled.textarea`
  padding: 8px;
  margin: 15px 0px;
  border: 1px solid #ccc;
  border-radius: 8px;
  min-height: 100px;
  resize: vertical;
  font-family: inherit;
  width: 100%;

  @media (max-width: 740px) {
    width: auto;
  }
`;

const StyledReCAPTCHA = styled(ReCAPTCHA)`
    align-self: center;
    width: fit-content;
`;
