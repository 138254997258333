import React, { useState } from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import CompanyInfo from "./CompanyInfo";
import Modal from "../common/Modal";
import Checkmark from "../../imgs/checkmark.svg";
import Error from "../../imgs/error.svg";
import ContentWrapper from "../common/ContentWrapper";

const Contact = () => {
    const [modalIsShown, setModalIsShown] = useState(false);
    const [modalMessage, setModalMessage] = useState(""); // Store the modal message
    const [successfulSubmission, setSuccessfulSubmission] = useState(false);  // Assume failed submission

    const handleModalClose = () => {
        setModalIsShown(false);
    };

    return (
        <section className="grey-section section" id="contact">
            <ContentWrapper style={{ flexDirection: "column" }}>
                <h1 className="title">Contact Us</h1>
                <p className="text" style={{ textAlign: "center" }}>
                    <em><strong>Requesting a quote is absolutely free!</strong></em>
                    <br />
                    Simply fill out the form below, and we'll get back to you with your personalized estimate.
                </p>
                <ContactForm
                    setModalIsShown={setModalIsShown} 
                    setModalMessage={setModalMessage}
                    setSuccessfulSubmission={setSuccessfulSubmission}
                />
                <CompanyInfo />

                {modalIsShown && (
                    <Modal close={handleModalClose}>
                        <ModalContent>
                            <ModalIcon 
                                src={successfulSubmission ? Checkmark : Error}
                                alt={successfulSubmission ? "Success icon" : "Error icon"}
                            />
                            <ModalHeader>{successfulSubmission ? "Success!" : "Error!"}</ModalHeader>
                            <ModalText>{modalMessage}</ModalText>
                        </ModalContent>
                    </Modal>
                )}
            </ContentWrapper>
        </section>
    )
};

export default Contact;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalHeader = styled.h2`
    color: black;
    margin: 0px;
`;

const ModalText = styled.div`
    padding: 10px;
    text-align: center;
    font-size: 18px;
    color: black;
`;

const ModalIcon = styled.img`
    display: flex;
    align-self: center;
    justify-self: center;
    width: 100px;
    height: auto;
`;

