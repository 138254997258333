import React from "react";
import styled from "styled-components";
import BrabhamFamilyImg from "../../imgs/brabham-family.svg";
import Button from "../common/Button";
import { HashLink } from "react-router-hash-link";
import ContentWrapper from "../common/ContentWrapper";

const About = () => {
  return (
    <section className="white-section section" id="about">
      <StyledContentWrapper>
        <Image src={BrabhamFamilyImg} alt="The Brabham Family" />
        <TextContent className="text" >
          <h1 className="title">Who We Are</h1>
          <p>
            L.R. Brabham, Inc. is an electrical contracting company based in Springfield, Oregon,
            with a history spanning over five decades. The company was originally founded in 1973 as
            Brabham-Haney Electric by Larry Brabham Sr. and John Haney. Just four months after its
            establishment, Larry Brabham Sr. bought out Haney’s share, rebranding the business as L.R.
            Brabham, Inc. From its inception, the company focused on providing timely service and
            high-quality workmanship, which helped it grow from the ground up in the Eugene and
            Springfield community.
          </p>
          <p>
            Under Larry Brabham Sr.’s leadership, L.R. Brabham, Inc. established itself as a reliable
            electrical contractor, serving residential, commercial, and industrial clients across the
            Eugene, Springfield, Corvallis, Cottage Grove, and surrounding areas. The company built a
            reputation for its skilled electricians and its commitment to customer satisfaction,
            offering a wide range of services from basic repairs to complete electrical design and
            installation projects. In 1998, after more than 25 years of leading the company, Larry
            Brabham Sr. retired and passed ownership to his son, Larry Brabham Jr., who continues to
            run the business today.
          </p>
          <p>
            Since its founding, L.R. Brabham, Inc. has remained a family-owned, union electrical
            contractor, emphasizing responsiveness and quality installations at fair prices. With over
            40 years of experience by the early 2000s, the company has adapted to the evolving needs of
            its clients, expanding its expertise to include specialized services like network wiring,
            lighting maintenance, and electrical design/build projects. Headquartered at 68 W Q St,
            Springfield, OR, it has become a fundamental part of the local community, known for its
            dedication to both its customers and the region it serves.
          </p>
          <HashLink to="#contact" smooth style={{ textDecoration: "none" }} >
              <Button label="Reach Out to Us" variant="white-section" buttonWidth="300px" style={{ textDecoration: "none", marginBottom: "12px" }} />
          </HashLink>
        </TextContent>
      </StyledContentWrapper>
    </section>
  )
};

export default About;

const StyledContentWrapper = styled(ContentWrapper)`
  flex-direction: row-reverse;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Allows the text container to grow and shrink */
  max-width: 60%; /* Ensures text doesn't exceed 60% of the section */
  height: auto;
  overflow: hidden; /* Prevents overflowing text */

  @media (max-width: 1550px) {
    max-width: 75%;
  }

  @media (max-width: 1250px) {
    padding: 0px;
  }
`;

const Image = styled.img`
  margin: 12px 6px;
  flex: 1; /* Allows image to grow and shrink */
  max-width: 40%;
  height: auto;
  object-fit: contain; /* Ensures image retains its aspect ratio */

  @media (min-width: 1250px) and (max-width: 1550px) {
    max-width: 25%;
  }

  @media (max-width: 1250px) {
    max-width: 50%;
    padding: 0px;
  }
`;
