import "./App.css";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import Home from "./components/Home";
import About from "./components/about/About";
import GeneralServices from "./components/services/GeneralServices";
import PhotoGallery from "./components/photo_gallery/PhotoGallery";
import Contact from "./components/contact/Contact";
import NavBar from "./components/navigation/NavBar";
import Copyright from "./components/Copyright";
import HighlightedServices from "./components/services/HighlightedServices";

function App() {
  return (
    <BrowserRouter>
      <PageWrapper>
        <NavBar />
        <PageContent>
          <Home />
          <About />
          <GeneralServices />
          <HighlightedServices />
          {/* <PhotoGallery /> */}
          <Contact />
        </PageContent>
        <Copyright />
      </PageWrapper>
    </BrowserRouter>
  );
}

export default App;

const PageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const PageContent = styled.div`
  padding-bottom: 2.5rem;
`;
