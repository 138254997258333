import React from "react";
import styled from "styled-components";

const AddressForm = ({
    type, // expects 'service' or 'billing'
    formData,
    handleFormChange,
    countriesList,
    stateList,
    citiesList,
    setCountryID,
    setStateID,
    setCityID,
    countryID,
    stateID,
    cityID,
    disabledCountry,
    disabledState,
    disabledCity,
  }) => {
    const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
  
    return (
        <>
            <Label>{capitalizedType} Address</Label>
            <div className="form-row">
                <input
                    className="form-input"
                    type="text"
                    name={`${type}Street`}
                    value={formData[`${type}Street`]}
                    placeholder="Street Address*"
                    onChange={handleFormChange}
                    required
                    style={{ flex: 2 }}
                />
                <input
                    className="form-input"
                    type="text"
                    name={`${type}AddressLine2`}
                    value={formData[`${type}AddressLine2`]}
                    placeholder="Apt, Suite, etc. (Optional)"
                    onChange={handleFormChange}
                />
            </div>
            <div className="form-row">
                <select
                    className="form-input"
                    onChange={(e) => {setCountryID(e.target.value);}}
                    name={`${type}Country`}
                    value={countryID || ""}
                    disabled={disabledCountry}
                    required
                >
                    <option value="">{countryID ? formData[`${type}Country`] : "-- Select Country --"}</option>
                    {countriesList.map((_country) => (
                        <option key={_country.id} value={_country.id}>{_country.name}</option>
                    ))}
                </select>
                
                <select
                    className="form-input"
                    onChange={(e) => {setStateID(e.target.value);}}
                    name={`${type}State`}
                    value={stateID || ""}
                    disabled={disabledState}
                    required
                >
                    <option value="">{stateID ? formData[`${type}State`] : "-- Select State --"}</option>
                    {stateList.map((_state) => (
                        <option key={_state.id} value={_state.id}>{_state.name}</option>
                    ))}
                </select>

                <select
                    className="form-input"
                    onChange={(e) => {setCityID(e.target.value);}}
                    name={`${type}City`}
                    value={cityID || ""}
                    disabled={disabledCity}
                    required
                >
                    <option value="">{cityID ? formData[`${type}City`] : "-- Select City --"}</option>
                    {citiesList.map((_city) => (
                        <option key={_city.id} value={_city.id}>{_city.name}</option>
                    ))}
                </select>

                <input
                    className="form-input"
                    type="number"
                    name={`${type}Zip`}
                    value={formData[`${type}Zip`]}
                    placeholder="Zip/Postal Code*"
                    onChange={handleFormChange}
                    required
                />
            </div>
        </>
    );
  };

export default AddressForm;

const Label = styled.h3`
  font-weight: bold;
  margin-bottom: 0px;
`;