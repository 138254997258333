import React from "react";
import styled from "styled-components";

const ToggleIcon = ({ expanded }) => {
  const secondTitle = "secondary-title";
  return (
    <IconButton>
      <Icon className={expanded ? `open ${secondTitle}` : secondTitle}>
        <span></span>
        <span></span>
      </Icon>
    </IconButton>
  );
};

export default ToggleIcon;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 561px) {
    padding-right: 25px;
  }
`;

const Icon = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    background-color: var(--accent-color);
    width: 100%;
    height: 4px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  span:nth-child(1) {
    transform: rotate(0deg);
  }

  span:nth-child(2) {
    transform: rotate(90deg);
  }

  &.open span:nth-child(1) {
    transform: rotate(180deg);
  }

  &.open span:nth-child(2) {
    transform: rotate(180deg);
  }

  @media (max-width: 740px) {
    width: 25px;
    height: 25px;
  }
`;
