import React from "react";
import styled from "styled-components";

const Button = ({label, clickHandler, buttonWidth, buttonHeight, variant = "grey-section", ...props}) => {
    return (
        <Btn onClick={clickHandler} $buttonWidth={buttonWidth} $buttonHeight={buttonHeight} $variant={variant} {...props}>{label}</Btn>
    )
};

export default Button;

const Btn = styled.button`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 2px solid var(--accent-color);
    border-radius: 8px;
    padding: 5px;

    max-width: 300px;
    width: ${(props) => (props.$buttonWidth ? props.$buttonWidth : "100%")};
    height: ${(props) => (props.$buttonHeight ? props.$buttonHeight : "auto")};

    font-size: 25px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; /* Smooth hover effect */

    ${({ $variant }) =>
        $variant === "white-section"
        ? `
            background-color: var(--primary-color);
            color: var(--accent-color);
            
            &:hover {
                background-color: var(--accent-color);
                color: var(--primary-color);
            }
        `
        : `
            background-color: var(--accent-color);
            color: var(--primary-color);
            
            &:hover {
                background-color: var(--primary-color);
                color: var(--accent-color);
            }
        `
    }

    @media (max-width: 740px) {
        width: 150px;
        height: 40px;
        font-size: 18px;
    }
`;