import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import UseWindowDims from "../common/UseWindowDims";
import DesktopNav from "./DesktopNav";
import HamburgerMenu from "./HamburgerMenu";
import MobileLinks from "./MobileLinks";
import LRBLogoSmall from "../../imgs/lrb-logo-small.svg";

const NavBar = () => {
  const [display, toggleDisplay] = useState(false);
  const {width,} = UseWindowDims();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Grab the scroll position to change background of nav bar dynamically
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Grab the width of the screen to update the badding on the Logo image dynamically
  useEffect(() => {
    if (width > 740) {
      toggleDisplay(false);
    }
  }, [width])

  var useSmallWidth = (width < 740);
  
  return (
    <NavWrapper $scrollPosition={scrollPosition} >
      <NavContent>
        <LogoLink to="#home" smooth width={width} >
            <SmallLogo src={LRBLogoSmall} />
        </LogoLink>
        {useSmallWidth ? <HamburgerMenu display={display} toggleDisplay={toggleDisplay} /> : <DesktopNav />}
      </NavContent>
      {useSmallWidth ? <MobileLinks display={display} toggleDisplay={toggleDisplay} /> : <></>}
    </NavWrapper>
  );
};

export default NavBar;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100px;
  max-width: 100vw;
  overflow-x: hidden;
  position: fixed;
  z-index: 2;
  margin: auto;

  background-color: ${(props) => (props.$scrollPosition > 100 ? "var(--accent-color)" : "none")};
  text-shadow: ${(props) => (
      props.$scrollPosition > 100 ? "none" : "2px 2px 0px black, -2px -2px 0px black, -2px 2px 0px black, 2px -2px 0px black;"
  )};
  transition: background-color 0.3s ease-in-out;

  @media (max-width: 740px) {
    height: auto;
    align-items: normal;
    flex-direction: column;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  min-height: 100px;
`;

const LogoLink = styled(HashLink)`
  padding-left: ${(props) => ((props.width <= 740) ? "20px" : undefined)};
`;

const SmallLogo = styled.img`
  margin: 12px 12px;
  height: 70px;
  width: auto;

  @media (min-width: 741px) and (max-width: 1800px) {
    height: 60px;
  }

  @media (max-width: 740px) {
    height: 50px;
  }

  @media (max-width: 450px) {
    height: 40px;
  }

  @media (max-width: 375px) {
    height: 30px;
  }
`;
