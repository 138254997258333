import React from "react";
import styled from "styled-components";


const GeneralServiceCard = ({header, description, image, imageDescription, imageSize, rowReverse}) => { 
    return (
        <GeneralServiceCardWrapper $rowReverse={rowReverse}>
            <TextWrapper className="text">
                <h2 className="secondary-title" style={{ textAlign: "center" }}>{header}</h2>
                {description.split("<br />").map((line, index) => (
                    <TextContent key={index}>{line}</TextContent>
                ))}
            </TextWrapper>
            <ImageDiv>
                <Image src={image} alt={imageDescription} $size={imageSize} />
            </ImageDiv>
            {/* <Image src={image} alt={imageDescription} $size={imageSize} /> */}
        </GeneralServiceCardWrapper>
    );
};

export default GeneralServiceCard;

const GeneralServiceCardWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$rowReverse ? "row-reverse" : "row")};
    margin-top: 8px;
    margin-bottom: 8px;

    @media (max-width: 740px) {
        flex-direction: column-reverse;
        align-items: center;
        max-width: 75%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    padding: 20px;

    @media (max-width: 740px) {
        margin: 10px;
    }
`;

const TextContent = styled.p`
    @media (max-width: 740px) {
        text-align: center;
    }
`;

const ImageDiv  = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    @media (max-width: 740px) {
        margin: 10px;
    }
`;

const Image = styled.img`
    margin: 12px 6px;
    flex: 1;
    max-width: 100%;
    min-width: 150px;
    height: auto;
    object-fit: contain;
    border: 5px solid var(--accent-color);
`;
