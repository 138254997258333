import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import LinksData from "./LinksData";

const MobileLink = ({display, toggleDisplay}) => {
    return (
        <LinkWrapper display={display}>
            {LinksData.map((link, index) => (
                <SingleLink
                    key={index}
                    to={link.hashLink}
                    onClick={() => {toggleDisplay(!display)}}
                    smooth
                    style={{ textDecoration: "none" }}
                    $isFirst={index === 0}
                >
                    {link.label}
                </SingleLink>
            ))}
        </LinkWrapper>
    )
}

export default MobileLink;

const LinkWrapper = styled.div`
  max-height: ${(props) => (props.display ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  flex-direction: column;
  font-size: 20px;
`;

const SingleLink = styled(HashLink)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: solid 1px var(--secondary-color);
  border-top: ${(props) => props.$isFirst ? "solid 1px var(--secondary-color)" : "none"};
  color: white;

  &:hover {
    cursor: pointer;
    background-color: #8e8e8e;
  }
`;
