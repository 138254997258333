import React, { useState } from "react";
import styled from "styled-components";
import HighlightedServiceCard from "./HighlightedServiceCard";
import Commercial from "../../imgs/commercial.svg";
import PanelUpgrade from "../../imgs/panel-upgrade.svg";
import Generators from "../../imgs/generators.svg";
import Remodels from "../../imgs/remodels.svg";
import QMeritBadge from "../../imgs/qmerit-badge.svg";
import NewConstruction from "../../imgs/new-construction.svg";
import DesignBuild from "../../imgs/design-build.svg";
import ServiceCalls from "../../imgs/service-calls.svg";
import BrabhamFamilyImg from "../../imgs/brabham-family.svg";
import ContentWrapper from "../common/ContentWrapper";

const HighlightedServices = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleCard = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const HighlightedServiceContent = [
        {
            name: "EV Chargers",
            narrative: `
            Given the increasing adoption of electric vehicles and the corresponding need for charging infrastructure, it's
            reasonable to imagine the company's involvement in this emerging field. This is why L.R. Brabham, Inc. chose to
            become a Qmerit certified solutions partner. Qmerit is leading the charge in electrification—the shift from fossil
            fuels to a more sustainable, electric-powered future. We're making the energy transition easy for everyone by connecting
            all stakeholders around the shared goal of delivering exceptional installation experiences for homeowners.
            <br />
            Only select electrical service providers earn the exclusive Qmerit certification, a symbol of excellence in electrification.
            You can depend on Qmerit-certified partners to provide trusted installation solutions that meet the highest standards for
            safety and service quality.
            <br />
            All Qmerit Certified Solutions Partners are market leaders in electrification services and:
            - Expert consultants and installers for all home electrification technologies
            - Properly licensed, fully insured and thoroughly background-checked
            - Compliant with all applicable worker's compensation guidelines
            - Required to secure permits to verify installations meet local building codes  
            - Trained continuously on safety, industry best practices and the latest technologies
            - Experienced in working with all major technology brands
            `,
            imgSrc: QMeritBadge,
            imgAlt: "The QMerit Logo.",
            disableImgBorder: true
        },
        {
            name: "Panel Upgrades",
            narrative: `
            When it comes to panel upgrade work, L.R. Brabham, Inc. focuses on enhancing the safety, capacity, and functionality of
            electrical systems. A panel upgrade typically involves replacing an outdated or undersized electrical panel with a modern,
            higher-capacity one to accommodate increased power demands, improve energy efficiency, and ensure compliance with current
            electrical codes. This process is critical for homes or businesses experiencing frequent circuit breaker trips, flickering
            lights, or the need to support additional appliances, equipment, or electric vehicle chargers.
            `,
            imgSrc: PanelUpgrade,
            imgAlt: "A picture representing a panel upgrade."
        },
        {
            name: "New Construction",
            narrative: `
            In the realm of new construction, L.R. Brabham, Inc. excels at providing comprehensive electrical services tailored
            to the unique demands of each project. For residential builds, we handle everything from wiring newly constructed
            homes to installing lighting fixtures and electrical panels, ensuring that each system is safe, efficient, and
            designed to meet the needs of modern homeowners. On the commercial side, L.R. Brabham, Inc. tackles larger-scale
            projects, such as office buildings, retail spaces, and tenant improvements. Our work often involves installing
            complex electrical systems, including sub-panels, circuits for specialized equipment, and advanced lighting designs.
            In industrial new construction, our company's capabilities shine through in projects requiring robust electrical
            infrastructure. Whether it's wiring for heavy machinery, designing power distribution systems, or setting up temporary
            electrical solutions during the construction phase, L.R. Brabham, Inc. brings decades of experience to ensure durability
            and performance.
            `,
            imgSrc: NewConstruction,
            imgAlt: "A picture of new construction."
        },
        {
            name: "Generators",
            narrative: `
            When it comes to generator installations, L.R. Brabham, Inc. offers tailored services to meet the diverse needs of its
            clients. Whether it's a homeowner seeking a reliable backup power source for their residence, a business requiring
            uninterrupted electricity for critical operations, or an industrial facility needing robust power solutions, the company
            has the knowledge and capability to design and install systems that fit the bill. Our process begins with understanding
            the specific power requirements of the client—assessing factors like load demands, usage patterns, and environmental
            conditions—to recommend and install the most suitable generator.
            `,
            imgSrc: Generators,
            imgAlt: "A picture of a generator."
        },
        {
            name: "Service Calls",
            narrative: `
            For residential customers, a typical service call might involve troubleshooting a sudden power outage, repairing faulty
            wiring, or upgrading an outdated electrical panel to meet modern safety codes. Homeowners might reach out after noticing
            flickering lights or a tripped circuit breaker that won't reset. Commercial service calls often tackle more complex
            challenges, such as designing and installing complete electrical systems for new office spaces or addressing power
            disruptions in operational businesses. The L.R. Brabham, Inc. team assesses the situation, upgrades the circuitry if
            needed, and ensures minimal downtime so the business can resume normal operations quickly. Industrial clients, meanwhile,
            might require service for heavy-duty electrical systems, such as machinery malfunctions or facility-wide power upgrades.
            An industrial call could involve repairing a relay malfunction that's halting production or installing high-voltage
            distribution systems to support expanded operations. The company's electricians, backed by decades of industry knowledge,
            work swiftly to minimize disruptions, often coordinating with facility managers to meet tight timelines and strict safety
            standards.
            `,
            imgSrc: ServiceCalls,
            imgAlt: "A picture representing a service call."
        },
        {
            name: "Remodels",
            narrative: `
            In residential remodels, L.R. Brabham, Inc. excels at transforming homes by upgrading electrical systems to meet modern
            demands. This includes rewiring older properties to comply with current codes, installing new lighting fixtures to
            enhance ambiance, and adding circuits for renovated kitchens, bathrooms, or home additions. For commercial remodels,
            L.R. Brabham, Inc. brings a comprehensive approach, handling complex electrical design and installation. Our portfolio
            includes constructing demising walls with electrical integration for tenant spaces, and setting up energy-efficient
            systems for offices or retail environments. We also offer wiring and cabling for modern tech needs, such as security
            cameras, networking, and telephone lines, ensuring that remodeled commercial spaces are both functional and future-ready.
            When it comes to industrial remodel work, L.R. Brabham, Inc. excels in transforming and upgrading existing facilities to
            meet modern operational demands. Our industrial projects often involve reconfiguring electrical systems in manufacturing
            plants, warehouses, and other large-scale operations where efficiency, safety, and reliability are paramount.
            `,
            imgSrc: Remodels,
            imgAlt: "A picture of electrical work done on a remodel."
        },
        {
            name: "Design/Build",
            narrative: `
            In its design/build approach, L.R. Brabham, Inc. offers a seamless, integrated process that takes projects from
            initial concept through to completion. This begins with our team of experienced designers collaborating closely
            with clients to understand their specific needs—whether it's wiring a new commercial building, designing an industrial
            electrical system, or installing residential landscape lighting. The company prides itself on its ability to tailor
            solutions, blending functionality with innovative design.
            `,
            imgSrc: DesignBuild,
            imgAlt: "A picture representing design and build of electrical systems."
        },
    ];

    return (
        <HighlightedServicesSection className="grey-section section" id="highlighted-services" $image={BrabhamFamilyImg}>
            <ContentWrapper style={{ flexDirection: "column" }}>
                {HighlightedServiceContent.map((highlightedServiceContent, index) => (
                    <HighlightedServiceCard
                        key={index}
                        serviceName={highlightedServiceContent.name}
                        serviceNarrative={highlightedServiceContent.narrative}
                        imgSrc={highlightedServiceContent.imgSrc}
                        imgAlt={highlightedServiceContent.imgAlt}
                        disableImgBorder={highlightedServiceContent.disableImgBorder ? true : false}
                        isOpen={activeIndex === index}
                        onToggle={() => toggleCard(index)}
                    />
                ))}
            </ContentWrapper>
        </HighlightedServicesSection>
    );
};



export default HighlightedServices;

const HighlightedServicesSection = styled.section`
  background-image: url(${Commercial});
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  position: relative;

  // Opacity adjustment
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: -1;
  }
`;
